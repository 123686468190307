<template>
<v-btn @click="create">Créer pdf de la ville</v-btn>
</template>

<script>
import {
    jsPDF
} from "jspdf"
import {
    storage
} from "../main"
import {
    getBytes,
    ref
} from 'firebase/storage'
export default {
    props: ["place"],
    methods: {
        async create() {
            let pageHeight = 297;
            let pageWidth = 210;
            let pageSize = [pageHeight,pageWidth];

            let pdf = new jsPDF({format:pageSize,orientation:"p"});
            
            let textWidth = pageWidth*0.7;
            let textHeight = 4;
            let baseFontSize = textHeight * 72 / 25.5

            pdf.setFontSize(2.5 * baseFontSize);
            pdf.text(pageWidth/2, pageHeight * 0.1, this.place.name, {
                align: "center"
            });

            let firstDay = true;

            for (let day of this.place.days) {
                pdf.setFontSize(1.5 * baseFontSize);
                pdf.text(pageWidth/2, firstDay ? pageHeight * 0.1 + textHeight * 4 : pageHeight * 0.1, day.title ? day.title + " - " + day.dateFrancaise : day.dateFrancaise, {
                    align: "center"
                });
                pdf.setFontSize(baseFontSize);
                let text = day.description.replaceAll(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');
                let totalWidth = pdf.getStringUnitWidth(text) * textHeight;
                let n_pages = totalWidth / textWidth / ((pageHeight/1.3) / (textHeight * 1.5))
                console.log("N pages ", n_pages);

                let length_text = text.length
                let characters_per_page = Math.floor(length_text / n_pages);
                let start_page = 0;
                let end_page = characters_per_page;
                let i = 0;
                let currentHeight = 0;
                while (start_page < length_text) {
                    if (i > 0)
                        pdf.addPage(pageSize,"p");
                    
                    currentHeight = pageHeight * 0.1;
                    
                    if (i == 0)
                        currentHeight = firstDay ? pageHeight * 0.1 + textHeight * 6 : pageHeight * 0.1 + textHeight * 3
                    
                    if (i == 0)
                        end_page = Math.floor(end_page * 0.9)

                    end_page = Math.min(end_page, length_text);
                    
                    while (end_page < length_text && text[end_page] != " ") {
                        end_page--;
                        console.log("end_page ",end_page," char ",text[end_page]);
                    }
                    
                    pdf.text(pageWidth*0.15, currentHeight, text.substring(start_page, end_page), {
                        maxWidth: textWidth,
                        align: "justify"
                    });
                    currentHeight = 0.1 + (end_page - start_page) / characters_per_page * 0.8
                    console.log("currentHeight ",currentHeight);
                    start_page = end_page
                    end_page = Math.min(start_page + characters_per_page, length_text);
                    console.log("Start ",start_page," end ",end_page);
                    i++;
                    
                }
                let currentWidth = pageWidth * 0.1;
                let notNew = true;
                let numberOfImagesPerRow = 3
                let imageWidth = 0.8 / (numberOfImagesPerRow * 1.2 - 0.2) * pageWidth
                let imageHeight = 0.12 * pageHeight
                currentHeight *= pageHeight
                console.log("currentHeight ",currentHeight);
                
                let images = await Promise.all(day.thumbnails.map(url => getBytes(ref(storage, url))));
                if (currentHeight > pageHeight * 0.9 - imageHeight) {
                    currentHeight = pageHeight * 0.1;
                    pdf.addPage(pageSize,"p");
                }
                console.log("currentAfter ", currentHeight);
                
                
                images.forEach(image => {
                    notNew = true;
                    image = new Uint8Array(image);
                    let properties = pdf.getImageProperties(image);
                    let factor = Math.min(imageHeight/properties.height,imageWidth/properties.width);
                    pdf.addImage(image, "JPEG", currentWidth + (imageWidth - factor*properties.width) / 2, currentHeight, factor*properties.width, factor*properties.height);
                    currentWidth += imageWidth * 1.2;
                    if (currentWidth > pageWidth * 0.91 - imageWidth) {
                        currentWidth = pageWidth * 0.1;
                        currentHeight += imageHeight * 1.2;
                    }
                    if (currentHeight > pageHeight * 0.9 - imageHeight) {
                        currentHeight = pageHeight * 0.15;
                        notNew = false;
                        pdf.addPage(pageSize,"p");
                    }
                })
                if (notNew)
                    pdf.addPage(pageSize,"p");
                firstDay = false;
            }

            pdf.save("test.pdf")
        }
    }
}
</script>

<style>

</style>
