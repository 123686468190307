import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Destination from '../views/Destination.vue'
import Continent from '../views/Continent'
import Pays from '../views/Pays'
import {auth} from '../main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path:'/continents',
    name:"Continents",
    component: () => import(/* webpackChunkName: "continents" */ '../views/Continents.vue')
  },
  {
    path:'/continent/:continent',
    name:"Continent",
    component:Continent,
  },
  {
    path:'/continent/:continent/pays/:country',
    name:"Pays",
    component:Pays
  },
  {
    path:"/continent/:continent/pays/:country/lieu/:place",
    name:"Destination",
    component:Destination
  },
  {
    path:"/stories",
    name:"Stories",
    component: () => import(/* webpackChunkName: "historique" */ '../views/Stories.vue')
  },
  {
    path:'/connexion',
    name:'Connection',
    component: () => import(/* webpackChunkName: "profil" */ '../views/Connection.vue')
  },
  {
    path:'/profil',
    name:'Profil',
    component: () => import(/* webpackChunkName: "profil" */ '../views/Profil.vue')
  },
  {
    path:'/blog/nouveau',
    name:'Nouveau Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/EditArticle.vue')
  },
  {
    path:'/blog/:id',
    name:'Show Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/ShowBlog.vue')
  },
  {
    path:'/blog/edit/:id',
    name:'Edit Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/EditArticle.vue')
  },
  {
    path:'/blogs',
    name:'List Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/ListeBlog.vue')
  },
  {
    path:'/timeline',
    name:'Timeline',
    component:() => import(/* webpackChunkName: "historique" */ '../views/Timeline.vue')
  },
  {
    path:'/visites',
    name:"Visits",
    component:() => import(/* webpackChunkName: "admin" */ '../views/Visitors.vue')
  },
  {
    path:'/commentaires',
    name:"Comments",
    component:() => import(/* webpackChunkName: "admin" */ '../views/Comments.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to,from,next)=>{
  if (to.name!="Connection" && auth.currentUser==null){next('/connexion')}
  else if((!auth.currentUser || !auth.currentUser.displayName || auth.currentUser.displayName.length==0) && to.name!='Profil' && to.name!="Connection"){next('/profil')}
  else {
    if(to.name=="Visits" || to.name=="Comments"){
      let idToken = await auth.currentUser.getIdTokenResult();
      let admin = !!idToken.claims.admin && idToken.claims.admin;
      if (!admin) next('/');
    } 
    next()
  }
})

export default router
