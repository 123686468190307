<template>
  <v-app>
    
    <v-app-bar
      app
      color="#D9D9D9"
      dark
      height="110px"
    >
      
     <v-app-bar-nav-icon class="ml-0 mr-4 mr-md-0 ml-md-3 navbar" :height="phone ?  '40px' : '64px'" :width="phone ?  '40px' : '64px'" style="background-color:#3B685963" color="black" @click="drawer_opened = !drawer_opened"></v-app-bar-nav-icon>
      <v-layout row justify-center>
        <v-flex xs8 md7 v-if="title.length>0">
          <div class="titre important justify-center" style="cursor:pointer" link @click="go_home">{{title}}</div>
          <div class="subtitle important justify-center">{{subtitle}}</div>
        </v-flex>
        <v-flex class="" xs2 md1><v-img src="/favicon.ico" height="72px" width="67px" contain></v-img></v-flex>
        <v-flex xs10 md10 v-if="title.length==0" align-self-center>
        </v-flex>
        
      </v-layout>      
      
      <div style="position:relative;">

        <ais-instant-search
          index-name="tout"
          :search-client="searchClient"
          class="">
              <ais-configure :hits-per-page.camel=5 />
              <ais-autocomplete>
                  <template v-slot="{ currentRefinement, indices, refine }">
                      <div>
                          <input type="search" id="input_search" v-if="title == '' " :class="{'elevation-15':title.length==0, 'pl-6':true}" @blur="clickedSearch"  @input="(e)=>{refine(e.target.value);}"
                            :style="{backgroundColor:title.length==0 ? 'white' : '#D9D9D9',top:'0px',bottom:'0px',right:'0px',width:title.length==0 ? phone ? '60vw' : '70vw' : phone ? '40px' : '64px',position:'absolute',zIndex:1,transform:phone ? '' : 'translateX(-12px)',borderRadius:phone ? '20px' : '32px',transition:'width 1s, box-shadow 1s',outline:'none'}"/>
 
                          <v-sheet elevation=3 v-if="currentRefinement && title == '' " class="py-2 px-0 px-md-2"  
                          :style="{backgroundColor:title.length==0 ? 'white' : '#D9D9D9',top:'100%',right:'60%',width:title.length==0 ? phone ? 'calc(60vw - 100%)' : 'calc( 70vw - 100%)' : phone ? '40px' : '64px',position:'absolute',zIndex:0,transform:phone ? '' : 'translateX(-12px)',transition:'width 1s, box-shadow 1s'}">
                              <v-list light>
                                  <v-list-item v-for="hit in indices[0].hits" :key="hit.objectID" @click.stop="()=>{refine('');goto(hit.type=='Pays' ? `/continent/${hit.continent}/pays/${hit.objectID}` : `/continent/${hit.continent}/pays/${hit.country}/lieu/${hit.objectID}`)}">
                                      <v-list-item-avatar width="64px" height="64px" v-if="hit.image_url"><v-img :src="hit.image_url"></v-img></v-list-item-avatar>
                                      <v-list-item-title style="flex: 1 1 0%">{{hit.name}}</v-list-item-title> 
                                  </v-list-item>
                              </v-list>
                          </v-sheet>
                      </div>
                  </template>
              </ais-autocomplete>
          </ais-instant-search>
        
        <div :style="{backgroundColor:'#D9D9D9',top:'0px',bottom:'0px',right:phone? '0px' : '12px',left:'0px',position:'absolute',zIndex:2,borderRadius:'50%'}"></div>
        <v-btn @click="clickedSearch" class="mr-0 mr-md-3" icon style="background-color:#3B685963;z-index:3" color="black" :height="phone ?  '40px' : '64px'" :width="phone ?  '40px' : '64px'">
        <v-icon>search</v-icon>
      </v-btn>
      </div>
      
      
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer_opened" color="#D9D9D9">
      <v-list nav>
        <v-list-item to="/" @click="drawer_opened = false">
          <v-list-item-title>Accueil</v-list-item-title>
        </v-list-item>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title>Continents<v-icon>expand_more</v-icon></v-list-item-title>
            </v-list-item>

          </template>
          <v-list>
            <v-list-item
              v-for="continent in continents"
              :key="continent.id"
              :to="`/continent/${continent.id}`"
              @click="drawer_opened=false"
            >
              <v-list-item-title>{{ continent.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-list-item v-for="link in links" :key="link.name" :to="link.path" @click="drawer_opened = false">
          <v-list-item-title>{{link.name}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="main" :style="{background: 'rgba(233, 233, 233, 0.51)',paddingTop:'110px'}" >
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import algoliasearch from 'algoliasearch'
import {firestore, auth} from './main'
import {doc,updateDoc} from 'firebase/firestore';
export default {
  name: 'App',

  data: () => ({
    drawer_opened:false,
    links:[{name:"Articles",path:"/blogs"},{name:"Toutes les stories",path:"/stories"},{name:"Frise chronologique",path:"/timeline"},{name:"Profil",path:"/profil"}],
    continents: [{name:"Afrique",id:"CHK9aT3cUGKBZTZHZsaf"}, {name:"Amérique du Sud", id:"GgkhPaPm4jfD2PWYzj7p"}, {name:"Asie", id:"J7XJuBcXQ18ikTCBAbjR"}, {name:"Europe", id:"KxUKkCJWECoD63fYWcBB"}, {name:"Amérique du Nord", id:"N73aYbooemO2EtRuMbHn"}
, {name:"Océanie", id:"RwExUDiD1rggsvuinDtx"}],
    title:"SUR LES TRACES DE PHILEAS",
    subtitle:"Le tour du monde en 80 photos",
    searchTerm:"",
    searchClient: algoliasearch(
            'DF0AIXS16G',
            'dcb4fde613ccb6ad883c70c72f542f00'
            )
  }),
  methods:{
    go_home(){
      this.drawer_opened = false;
      this.$router.push("/")
    },
    clickedSearch() {
      if(this.title=="") {
        setTimeout(()=>{this.title="SUR LES TRACES DE PHILEAS";
        this.subtitle="Le tour du monde en 80 photos"},150);
        
      }
      else {
        this.title="";
        this.subtitle="";
        setTimeout(()=>document.getElementById("input_search").focus(),150);
      }
    },
    goto(link){
      this.title="SUR LES TRACES DE PHILEAS";
      this.subtitle="Le tour du monde en 80 photos";
      this.$router.replace(link).catch(()=>{});
      console.log(link);
    }
  },
  computed:{
    phone(){return this.$vuetify.breakpoint.name=="xs" },
    console(){return console}
  },
  async created() {
    if(auth.currentUser && auth.currentUser.displayName && auth.currentUser.displayName.length > 0) {
      let ref_last = doc(firestore,"stats","recent_actions");
      updateDoc(ref_last,{[auth.currentUser.uid]:{name:auth.currentUser.displayName,date:new Date()}});
      let idToken = await auth.currentUser.getIdTokenResult();
      let admin = !!idToken.claims.admin && idToken.claims.admin;
      if (admin) {
        this.links.push({name:"Visites",path:"/visites"});
        this.links.push({name:"Commentaires",path:"/commentaires"})
      }
    }
  }
};
</script>
<style>
.titre.important{
  font-family: 'Life Savers' !important;
  font-style: normal;
  font-weight: 900;
  font-size: 32px !important;
  line-height: 39px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #caa140;

  text-shadow: 2px 2px 2px #caa140a0;

}
.main * {
  word-break: normal !important;
}
@media  (max-width:600px) {
  .titre.important{
  
  font-size: 18px !important;
  line-height: 25px;
  
  }
  .subtitle.important{
    font-size:15px !important;
    line-height: 18px;
  }
}
.subtitle.important {
font-family: 'Dancing Script';
font-style: normal;
font-weight: 400;
font-size: 28px;
line-height: 30px;

display: flex;
align-items: center;
text-align: center;

color: rgba(0, 0, 0, 0.74);
}
.center {
  display:flex;
  align-items: center;
  text-align: center;
}

body {
  background: rgba(233, 233, 233, 0.51);
}
</style>