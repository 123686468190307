<template>
  <v-overlay v-if="overlay" opacity="1">
          <v-btn icon class="mt-n5 mb-3" style="position:absolute;top:0vh;left:100vw;transform:translate(-150%,150%);z-index:2;" @click="removeOverlay">
                    <v-icon>close_fullscreen</v-icon>
         </v-btn>
          <v-carousel height="95vh" style="width:100vw;height:95vh !important" v-model="index_zoomed">
            <v-carousel-item v-for="story in stories" :key="story.image_url || story.thumbnail_url">
              <div v-if="story.description && story.description.length > 0" class="px-2 text-h6 text-md-h6" :style="{position:'absolute',display:'flex',alignItems:'center',background:'rgba(0, 0, 0, 0.3)',bottom:phone ? '50px': '50px',top:phone ? null:'0px', left:phone ? '0px': '70vw', right:phone ? '0px': '0px',paddingTop:phone ? '0px':'50px'}">
                {{story.description}}
              </div>
              <div :style="{height:'95vh',width:phone||!story.description||story.description.length==0 ? '100vw' : '70vw'}" >
                
                <v-img v-if="story.image_type=='image'" style="top: 50%;left:50%;position: relative;transform: translate(-50%,-50%);" :src="story.image_url" :max-height="phone && !!story.description && story.description.length>0 ? 'calc(95vh - 250px)': 'calc(95vh - 100px)'" max-width="100%" contain></v-img>
                <video v-else :src="story.image_url" style="max-height:calc(95vh - 200px);max-width:100%;top: 50%;left:50%;position: relative;transform: translate(-50%,-50%);" controls></video>
              </div>
            </v-carousel-item>
          </v-carousel>  
    </v-overlay>
</template>

<script>
export default {
    props:["index_zoomed","stories","overlay","removeOverlay"],
    computed:{
        phone(){return this.$vuetify.breakpoint.name == "xs"}
    }
}
</script>

<style>

</style>