import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            
            secondary: '#D4F5D6',
            accent: '#F7BF62',
            
          },
        },
      },
});
