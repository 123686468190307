<template>
    <v-container>
        <grid-click :liste="liste" :addPhoto="addPhoto"/>
        <h2 v-if="liste.length==0" class="text-center mb-4">Nous n'avons pas encore été sur ce continent, mais cela ne saurait tarder ;-)</h2>
        <v-row v-if="admin">
            <v-col class="col-12">
                <v-card>
                    <v-card-title>Nouveau pays</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field label="Nom" v-model="name_new_country" required></v-text-field>
                            <v-file-input prepend-icon="mdi-camera" label="Image" v-model="image_new_country" accept="image/*"></v-file-input>
                            <v-btn @click="addCountry">Ajouter pays</v-btn>
                        </v-form>
                    </v-card-text>
                    
                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</template>

<script>
/* eslint-disable  */
import GridClick from '../components/GridClick.vue'
import {collection, getDocs, doc, setDoc, updateDoc} from 'firebase/firestore';
import {auth, firestore, storage, compression} from '../main'
import {uploadBytes, ref, getDownloadURL, deleteObject} from 'firebase/storage'
import {Country} from '../classes.js';

export default {
  components: { GridClick },

    data:()=>{
        return {
            liste:[],
            name_new_country:"",
            image_new_country:[],
            admin:false
        }
    },
    computed:{
        continent(){return this.$route.params.continent},
    },
    methods:{
        async addCountry(){
            let collectionContinent = collection(firestore,"continents",this.continent,"pays");
            let new_country_ref = doc(collectionContinent);
            let new_country;
            let url = new URL("https://public.opendatasoft.com/api/records/1.0/search/")
            url.search = new URLSearchParams({dataset:"countries-codes",q:this.name_new_country})
            let res = await (await fetch(url)).json()
            let alpha_new_country = res.records[0].fields.iso2_code;
            if (this.image_new_country instanceof File) {
                let ref_thumbnail = ref(storage, `countries/${new_country_ref.id}/thumbnail-image_profil.${this.image_new_country.name.split('.').pop()}`);
                let data_thumbnail = await compression(this.image_new_country,600,0.9)
                await uploadBytes(ref_thumbnail, data_thumbnail);
                let link_thumbnail = await getDownloadURL(ref_thumbnail);
                new_country = new Country(this.name_new_country, alpha_new_country, new_country_ref.id,0, link_thumbnail, this.getLink(new_country_ref.id, 0))
            }
            else {
                new_country = new Country(this.name_new_country, alpha_new_country, new_country_ref.id, 0, null, this.getLink(new_country_ref.id, 0))    
            }
            setDoc(new_country_ref,new_country.toObject());
            this.liste.push(new_country);
            this.name_new_country = "";
            this.image_new_country = null;
            
        },
        async addPhoto(e,country) {
            if (! (e.target.files.length == 1))
                return
            let country_ref = doc(firestore, "continents", this.continent,"pays",country.firestore_id);
            if (country.thumbnail_url && country.thumbnail_url.length > 0)
                await deleteObject(ref(storage,country.thumbnail_url));
            //let ref_image = ref(storage, `countries/${country.firestore_id}/image_profil.${e.target.files[0].name.split('.').pop()}`);
            let ref_thumbnail = ref(storage, `countries/${country.firestore_id}/thumbnail-image_profil.${e.target.files[0].name.split('.').pop()}`);
            //let data_image = await compression(e.target.files[0],2000,0.9)
            let data_thumbnail = await compression(e.target.files[0],600,0.9)
            await uploadBytes(ref_thumbnail, data_thumbnail);
            //let link_image = await getDownloadURL(ref_image);
            let link_thumbnail = await getDownloadURL(ref_thumbnail);
            await updateDoc(country_ref, {thumbnail_url:link_thumbnail});
            country.thumbnail_url = link_thumbnail;
            this.$forceUpdate();

        },
        getLink(firestore_id, num_cities, place_id){
            if (num_cities == 1 && !this.admin) {
                return  "/continent/"+this.continent+"/pays/"+firestore_id+"/lieu/"+place_id
            }
            else {
                return  "/continent/"+this.continent+"/pays/"+firestore_id
            }
        }
    },
    async created(){
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;

        let docs = await getDocs(collection(firestore,"continents/"+this.continent+"/pays"));
        this.liste = docs.docs.map(doc=>{
            let data = doc.data();
            let link = this.getLink(doc.id, data.num_cities, data.place_id);
            
            return new Country(data.name,data.alpha,doc.id,data.num_cities,data.thumbnail_url,link);
            });
        this.liste = this.liste.sort((a,b)=>{return a.name.localeCompare(b.name)})
    },
    async beforeRouteUpdate(to, from, next) {
        if( to.name!="Continent")
            return;
        let docs = await getDocs(collection(firestore,"continents/"+to.params.continent+"/pays"));
        this.liste = docs.docs.map(doc=>{
            let data = doc.data();
            let link = this.getLink(doc.id, data.num_cities, data.place_id);
            return new Country(data.name,data.alpha,doc.id,data.num_cities, data.thumbnail_url,link);
            });
        this.liste = this.liste.sort((a,b)=>{return a.name.localeCompare(b.name)});
        next();
    },
}
</script>

<style>

</style>