import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
/* eslint-ignore-next-line */
import {getAuth} from 'firebase/auth'
/* eslint-ignore-next-line */
import {getFirestore} from 'firebase/firestore'
/* eslint-ignore-next-line */
import {getStorage} from 'firebase/storage';
Vue.config.productionTip = false
import InstantSearch from 'vue-instantsearch';

Vue.use(InstantSearch);
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAOigib80M8X-wx8ecSS1V8GqY8BdJg1E8",
  authDomain: "surlestracesdephileas.firebaseapp.com",
  projectId: "surlestracesdephileas",
  storageBucket: "surlestracesdephileas.appspot.com",
  messagingSenderId: "911075556023",
  appId: "1:911075556023:web:636b1ed68540f6393f6b32"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const auth = getAuth();
const firestore = getFirestore();
const storage = getStorage();
//connectFirestoreEmulator(firestore, 'localhost', 8081);
//connectAuthEmulator(auth, 'http://localhost:9099');
//connectStorageEmulator(storage, 'localhost',9199)


const compression = async (file, width,quality) =>{
  
  if (file.type.split("/")[0]=="video")
    return file
  const reader = new FileReader();
  const promise = new Promise((resolve)=>{
    reader.onload = event => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
            resizeMe(img,resolve,file,width,quality);    
          },
  reader.onerror = error => console.log(error);
    };

  });
  
  
  reader.readAsDataURL(file);
  return promise
}

// === RESIZE ====

function resizeMe(img,resolve,file,width=500, quality=0.9) {

  var canvas = document.createElement('canvas');

  const scaleFactor = width / img.width;
  canvas.width = width;
  canvas.height = img.height * scaleFactor;
  const ctx = canvas.getContext('2d');
                
  ctx.drawImage(img, 0, 0, width, img.height*scaleFactor);
  ctx.canvas.toBlob((blob) => {
      const newfile = new File([blob], file.name, {
              type: file.type,
              lastModified: Date.now()
          });
      resolve(newfile);
  }, file.type, quality); 

}


auth.onAuthStateChanged(() => {
  new Vue({
    router,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
})

export {auth, firestore, storage, compression}