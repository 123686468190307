<template>
    <v-container class="px-0">
    <v-row class="px-n3" wrap>
        <v-col class="col-12 col-sm-4 col-md-3" v-for="el in liste" :key="el.name">
            <v-card style="border-radius:20px;" :to="el.link">
                <div style="overflow:hidden;position:relative" v-if="el.thumbnail_url || el.image_url">
                    <v-img :src="el.thumbnail_url || el.image_url" aspect-ratio="1.1"></v-img>
                    <v-btn style="position:absolute;bottom:0px; left:0px;transform:translate(10%,-10%)" v-if="admin" icon @click.stop="(e)=>chooseFile(e,el)"><v-icon>refresh</v-icon></v-btn>
                </div>
                <v-btn v-else-if="admin" text @click.stop="(e)=>chooseFile(e,el)"><v-icon>add</v-icon>Ajouter photo</v-btn>
                <input @click.stop="" @change="(e)=>addPhoto(e,el)" type="file" accept="image/*" hidden :id="'input'+el.name"/>
                <v-card-title style="word-break:normal;">{{el.name}}</v-card-title>
            </v-card>
        </v-col>
    </v-row>
    </v-container>
</template>

<script>
import {auth} from '../main'
export default {
    props:["liste","addPhoto"],
    data(){
        return {admin:false}
    },
    methods:{
        chooseFile(e,el){
            e.stopPropagation();
            e.preventDefault();
            let input = document.getElementById('input'+el.name);
            input.click();
        }
    },
    async created(){
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
    }
}
</script>

<style>

</style>