
function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}
function dateFrance(date) {
    var options = {year: 'numeric', month: 'long', day: 'numeric' };
    var opt_weekday = { weekday: 'long' };
    var weekday = toTitleCase(date.toLocaleDateString("fr-FR", opt_weekday));

    return weekday + " " + date.toLocaleDateString("fr-FR", options)
}

function dateHeure(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes;
    return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear() + "  " + strTime;
  }

class Country {
    constructor(name, alpha, firestore_id, num_cities=0, thumbnail_url=null, link=null,place_id=null) {
        this.name = name;
        this.alpha = alpha;
        this.num_cities = num_cities;
        this.firestore_id = firestore_id;
        this.link = link
        if(thumbnail_url)
            this.thumbnail_url = thumbnail_url;
        if(place_id)
            this.place_id = place_id;

    }
    toObject(){
        let obj = {name:this.name, alpha:this.alpha, num_cities:this.num_cities};
        if (this.thumbnail_url)
            obj.thumbnail_url = this.thumbnail_url;
        if(this.place_id)
            obj.place_id = this.place_id
        return obj
    }
}

class Day {
    constructor(firestore_id, date, description, images, thumbnails, title="", comments){
        this.firestore_id = firestore_id;
        this.date = date;
        this.description = description;
        this.images = images;
        this.thumbnails = thumbnails;
        if(title) 
            this.title = title
        else
            this.title = ""
        if(comments)
            this.comments = comments
        else
            this.comments = [];
        this.answer = null;
        this.new_comment = "";
    }
    get dateFrancaise(){
        return dateFrance(this.date)
    }
    toObject() {
        return {date:this.date,description:this.description, images: this.images, thumbnails:this.thumbnails, title:this.title, comments:this.comments.map(comment=>comment.toObject())}
    }
}

class Comment {
    constructor(text, author, author_uid, date, response_text=null, response_author=null) {
        this.text = text;
        this.author = author;
        this.author_uid = author_uid;
        if(response_text)
            this.response_text = response_text;
        if(response_author)
            this.response_author = response_author;
        if(date){
            if(date instanceof Date)
                this.date = date;
            if(date.seconds)
                this.date = new Date(date.seconds*1000)
        }
    }
    toObject() {
        let obj = {text:this.text,author:this.author,author_uid:this.author_uid};
        if(this.response_text)
            obj.response_text = this.response_text;
        if(this.response_author)
            obj.response_author = this.response_author;
        if(this.date)
            obj.date = this.date;
        return obj;
    }
}

class Place {
    constructor(name,firestore_id, days, image_url=null, thumbnail_url=null,description=null, mosaique=null){
        this.name = name
        this.firestore_id = firestore_id
        this.days = days
        this.description = description
        this.image_url = image_url
        this.thumbnail_url = thumbnail_url;
        if (mosaique) {
            this.mosaique = mosaique;
        }
    }
    toObject() {
        return {name:this.name, image_url:this.image_url,thumbnail_url:this.thumbnail_url,description:this.description, mosaique:this.mosaique}
    }
}

class Blog {
    constructor(firestore_id,title,image_url, thumbnail_url, date, sections, comments) {
        this.firestore_id = firestore_id;
        this.title = title;
        if(image_url)
            this.image_url = image_url;
        else
            this.image_url = "";
        if (thumbnail_url)
            this.thumbnail_url = thumbnail_url;
        else
            this.thumbnail_url = ""
        if(date)
            this.date = date;
        else
            this.date = new Date();
        if (sections)
            this.sections = sections;
        else
            this.sections = [{category:"Soustitre",id:1}]
        if (comments) 
            this.comments = comments;
        else
            this.comments = []
        this.answer = null
        this.new_comment = null
        
    }
    toObject() {
        return {title:this.title, image_url:this.image_url, thumbnail_url:this.thumbnail_url, sections:this.sections, comments:this.comments, date:this.date}
    }
    fromObject(data, firestore_id) {
        this.title = data.title;
        this.firestore_id = firestore_id;
        this.image_url = data.image_url;
        this.thumbnail_url = data.thumbnail_url;
        if (data.sections)
            this.sections = data.sections;
        else
            this.sections = [{category:"Soustitre",id:1}]
        if (data.comments) 
            this.comments = data.comments.map(comment=>new Comment(comment.text,comment.author,comment.author_uid,comment.date,comment.response_text,comment.response_author));
        else
            this.comments = []
        if (data.date) {
            this.date = new Date(data.date.seconds*1000)
        }
        else {
            this.date = new Date();
        }
    }
}

export {Country, Place, Day, Comment, Blog, dateFrance, dateHeure};