<template>
    <v-container>
        <grid-click :liste="liste" :addPhoto="addPhoto"/>
        <v-row v-if="admin">
            <v-col class="col-12">
                <v-card>
                    <v-card-title>Nouveau lieu</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-text-field label="Nom" v-model="name_new_place" required></v-text-field>
                            <v-file-input prepend-icon="mdi-camera" label="Image" v-model="image_new_place" accept="image/*"></v-file-input>
                            <v-btn @click="addPlace">Ajouter lieu</v-btn>
                        </v-form>
                    </v-card-text>
                    
                </v-card>
            </v-col>
        </v-row>
        
    </v-container>
</template>


<script>
/* eslint-disable  */
import GridClick from '../components/GridClick.vue'
import {collection, getDocs, doc, setDoc, updateDoc} from 'firebase/firestore';
import {auth, compression, firestore, storage} from '../main'
import {uploadBytes, ref, getDownloadURL, deleteObject} from 'firebase/storage'

export default {
  components: { GridClick },

    data:()=>{
        return {
            liste:[],
            name_new_place:"",
            image_new_place:[],
            admin:false
        }
    },
    computed:{
        continent(){return this.$route.params.continent},
        country(){return this.$route.params.country},
    },

    methods:{
        async addPlace(){
            let collectionCountry = collection(firestore,"continents",this.continent,"pays",this.country,"lieux");
            let new_place_ref = doc(collectionCountry);
            let link = `/continent/${this.continent}/pays/${this.country}/lieu/${new_place_ref.id}`
            let new_place;
            if (this.image_new_place instanceof File) {
                let ref_image = ref(storage, `countries/${this.country}/${new_place_ref.id}/image_profil.${this.image_new_place.name.split('.').pop()}`);
                let ref_thumbnail = ref(storage, `countries/${this.country}/${new_place_ref.id}/thumbnail-image_profil.${this.image_new_place.name.split('.').pop()}`);

                let data_image = await compression(this.image_new_place,2000,0.9)
                let data_thumbnail = await compression(this.image_new_place,600,0.9)
                await Promise.all([uploadBytes(ref_image, data_image),uploadBytes(ref_thumbnail, data_thumbnail)]);
                let link_image = await getDownloadURL(ref_image);
                let link_thumbnail = await getDownloadURL(ref_thumbnail);
                new_place = {name:this.name_new_place, image_url:link_image, thumbnail_url:link_thumbnail}
            }
            else {
                new_place = {name:this.name_new_place}    
            }
            setDoc(new_place_ref,new_place);
            new_place.link = link;
            new_place.firestore_id = new_place_ref.id
            this.liste.push(new_place);
            this.name_new_place = "";
            this.image_new_place = null;
            
        },
        async addPhoto(e,place) {
            if (! (e.target.files.length == 1))
                return
            if (place.image_url && place.image_url.length > 0)
                await deleteObject(ref(storage,place.image_url));
            if (place.thumbnail_url && place.thumbnail_url.length > 0)
                await deleteObject(ref(storage,place.thumbnail_url));
            
            let ref_image = ref(storage, `countries/${this.country}/${place.firestore_id}/image_profil.${e.target.files[0].name.split('.').pop()}`);
            let ref_thumbnail = ref(storage, `countries/${this.country}/${place.firestore_id}/thumbnail-image_profil.${e.target.files[0].name.split('.').pop()}`);
            let data_image = await compression(e.target.files[0],2000,0.9)
            let data_thumbnail = await compression(e.target.files[0],600,0.9)
            await Promise.all([uploadBytes(ref_image, data_image),uploadBytes(ref_thumbnail, data_thumbnail)]);
            let link_image = await getDownloadURL(ref_image);
            let link_thumbnail = await getDownloadURL(ref_thumbnail);
            let place_ref = doc(firestore, "continents", this.continent,"pays",this.country,"lieux",place.firestore_id);
            await updateDoc(place_ref, {image_url:link_image, thumbnail_url:link_thumbnail});
            place.image_url = link_image;
            place.thumbnail_url = link_thumbnail;
            this.$forceUpdate();

        },

    },
    async created(){
        let docs = await getDocs(collection(firestore,"continents",this.continent,"pays",this.country,"lieux"));
        this.liste = docs.docs.map(doc=>{
            return {...doc.data(),firestore_id:doc.id,link:`/continent/${this.continent}/pays/${this.country}/lieu/${doc.id}`}
            });
        this.liste = this.liste.sort((a,b)=>{return a.name.localeCompare(b.name)})
        let idToken = await auth.currentUser.getIdTokenResult();
        this.admin = !!idToken.claims.admin && idToken.claims.admin;
    },
    async beforeRouteUpdate(to, from,next) {
        if( to.name!="Pays")
            return;
        let docs = await getDocs(collection(firestore,"continents",to.params.continent,"pays",to.params.country,"lieux"));
        this.liste = docs.docs.map(doc=>{
            return {...doc.data(),firestore_id:doc.id,link:`/continent/${this.continent}/pays/${this.country}/lieu/${doc.id}`}
            });
        this.liste = this.liste.sort((a,b)=>{return a.name.localeCompare(b.name)});
        next();
    }
}
</script>

