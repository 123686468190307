<template>
  <div style="position:relative">
    <overlay-photo
      :stories="stories"
      :overlay="overlay"
      :removeOverlay="()=>overlay=false"
      :index_zoomed="story_zoomed"
    />
    <v-overlay v-if="new_story">
      <v-btn
        icon
        class="mt-n5 mb-3"
        style="
          display: absolute;
          top: 0vh;
          left: 100%;
          transform: translateX(-100%);
        "
        @click="new_story = false"
      >
        <v-icon>close_fullscreen</v-icon>
      </v-btn>
      <v-card light width="80vw">
        <v-card-title>Nouvelle story</v-card-title>
        <v-card-text>
          <v-file-input
            prepend-icon="mdi-camera"
            v-model="new_story_file"
            label="Photo ou vidéo"
            accept="image/*,video/*"
          ></v-file-input>
          <v-text-field
            label="Description"
            v-model="new_story_description"
          ></v-text-field>
          <v-btn @click="addStory">Ajouter</v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay v-if="changeTextColor">
      <v-card>
        <v-card-text>
          <h4>Couleur texte principal</h4>
          <v-color-picker v-model="updated_main_text_color" hide-canvas mode="rgba" show-swatches></v-color-picker>
          <h4>Couleur texte secondaire</h4>
          <v-color-picker v-model="updated_secondary_text_color" hide-canvas mode="rgba" show-swatches></v-color-picker>
          <v-btn class="my-4" @click="updateTextColor">Changer les couleurs</v-btn><br/>
          <v-btn @click="()=>changeTextColor=false">Annuler</v-btn>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay v-if="proportion">
      <v-card>
        <v-card-title>Envoie de la vidéo en cours</v-card-title>
        <v-card-text>
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="proportion*100"
            color="teal"
          >
            {{ Math.floor(proportion*100) }}
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-overlay>
    <v-img
      style="position: relative"
      width="100vw"
      height="calc(100vh - 110px)"
      :src="mainImageURL"
    >
    <v-btn icon v-if="admin" style="position:absolute;bottom:0px; left:0px;" @click="openMainInput">
      <v-icon>refresh</v-icon>
    </v-btn>
    <v-btn icon v-if="admin" style="position:absolute;bottom:0px; right:0px;" @click="()=>changeTextColor=true">
      <v-icon>edit</v-icon>
    </v-btn>
    <input accept="image/*" type="file" hidden id="changeMainImage" @change="changeMainImage"/>
      <div class="bubble">
        <div class="text-bubble">
          <div class="blog center mb-9" :style="{color:main_text_color}">Le Blog Voyage d'Emma et Adriano</div>
          <div class="pret center" :style="{color:secondary_text_color}">
            Prêts à nous suivre dans notre périple ?
          </div>
        </div>
      </div>
    </v-img>
    <v-layout v-if="admin || breaking_news.length > 0" class="px-4 py-3" style="background-color:rgb(217, 217, 217); letter-spacing:2px;color:rgb(195 124 21);font-weight:600">
      <v-flex xs1 v-if='admin'><v-btn icon @click="()=>{if(editing_breaking) breaking_news = previous_breaking;editing_breaking=!editing_breaking}"><v-icon>edit</v-icon></v-btn></v-flex>
      <span v-if="!editing_breaking" style="align-self:center">{{breaking_news}}</span>
      <v-flex v-if="editing_breaking" xs10><v-text-field hide-details dense outlined label="Nouvelles informations" v-model="breaking_news"></v-text-field></v-flex>
      <v-flex xs1 v-if="editing_breaking"><v-btn icon @click="saveBreaking"><v-icon>save</v-icon></v-btn></v-flex>
    </v-layout>
    <div class="stories" v-if="admin || stories.length > 0">
      <div class="title_stories text-center pt-5 pb-4 pb-md-6">
        Nos Stories:
      </div>
      <v-row
        class="py-3 px-8 ma-0"
        style="overflow-x: auto; flex-wrap: nowrap"
      >
        <v-col v-if="admin" class="col-4 col-sm-2 pa-1">
          <v-sheet style="border-radius: 40px" color="#356354CC" height="20vh">
            <v-btn
              icon
              @click="new_story = true"
              large
              style="top: 50%; left: 50%; transform: translate(-50%, -50%)"
            >
              <v-icon large>add_circle_outline</v-icon>
            </v-btn>
          </v-sheet>
        </v-col>
        <v-col
          style="border-radius: 20%"
          class="col-4 col-sm-2 px-3 py-2"
          v-for="(story, i) in stories"
          :key="story.image_url"
          @click="
            () => {
              story_zoomed = i;
              overlay = true;
            }
          "
        >
          <v-img
            v-if="story.image_type == 'image'"
            :src="story.thumbnail_url"
            aspect-ratio="1.2"
            height="20vh"
            contain
            style="cursor:pointer"
          ></v-img>
          <video
            id="player"
            style="
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: relative;
            "
            v-else
            width="100%"
            controls
          >
            <source :src="story.image_url" type="video/mp4" />
          </video>
        </v-col>
      </v-row>
      <v-layout class="pb-5 mt-5 mt-md-3" justify-center>
        <v-flex xs12 class="text-center"
          ><v-btn
            elevation="0"
            color="#FFFFFF"
            style="color: #587d71"
            rounded
            to="/stories"
            >Voir nos précédentes stories</v-btn
          ></v-flex
        >
      </v-layout>
    </div>
    <div class="decouvertes">
      <div class="title_decouvertes text-center pt-5 pb-4 py-md-7">
        Nos dernières découvertes:
      </div>
      <v-layout row justify-space-around v-if="!phone" class="px-3 ma-0 mt-3">
        <v-flex
          xs12
          sm3
          v-for="recent in recents"
          :key="recent.name"
          class="text-center"
        >
          <v-card
            style="border-radius: 20px"
            :to="`/continent/${recent.continent}/pays/${recent.country}/lieu/${recent.place_id}`"
          >
            <v-img aspect-ratio="1.1" :src="recent.thumbnail_url"></v-img>
            <v-card-title
              style="display: grid;word-break:normal;"
              class="text-center grey--text text--darken-3"
              >{{ recent.name }}</v-card-title
            >
          </v-card>
        </v-flex>
      </v-layout>
      <v-carousel style="height: auto" hide-delimiters v-else>
        <v-carousel-item
          class="px-6"
          style="height: auto"
          v-for="recent in recents"
          :key="recent.name"
        >
          <v-card
            style="border-radius: 20px"
            :to="`/continent/${recent.continent}/pays/${recent.country}/lieu/${recent.place_id}`"
          >
            <v-img
              aspect-ratio="1.1"
              height="auto"
              :src="recent.thumbnail_url"
            ></v-img>
            <v-card-title
              style="display: grid;word-break:normal"
              class="text-center grey--text text--darken-3"
              >{{ recent.name }}</v-card-title
            >
          </v-card>
        </v-carousel-item>
      </v-carousel>
      <v-row class="ma-0 text-center py-6 mt-6">
        <div class="flex text-center">
          <v-btn
            elevation="0"
            rounded
            color="#8FB590"
            style="color: white"
            to="/continents"
            >Voir toutes nos destinations</v-btn
          >
        </div>
      </v-row>
    </div>
    <div class="carte" style="padding-bottom:calc(10%);position: relative">
      <div class="py-5 py-md-6 text-center title_carte px-3">
        Les pays que nous avons visités
      </div>
      <div id="mapdiv" style="width: 100%;height:450px"></div>
      <div
        :style="{
          position: 'absolute',
          background: '#F5F0D4',
          bottom: phone ? '5%' : '15%',
          height: '10%',
          left: '0px',
          width: phone ? '30%' : '20%',
        }"
      ></div>
    </div>
  </div>
</template>

<style>
.v-image.v-carousel__item {
  height: auto !important;
}
.center {
  display: flex;
  align-items: center;
  text-align: center;
}
.decouvertes {
  background: rgba(233, 233, 233, 0.51);
}
.stories {
  background: rgba(53, 99, 84, 0.8);
}
.carte {
  background: #f5f0d4;
}
.title_stories {
  font-family: "Life Savers";
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}

.title_decouvertes {
  font-family: "Life Savers";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #78a779;
  margin-left: auto;
  margin-right: auto;
}
.title_carte {
  font-family: "Life Savers";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #8fb590;
}
.blog {
  font-family: "Dancing Script";
  font-style: normal;
  font-weight: 700;
  font-size: min(60px,7.5vh);
  line-height: min(72px,8vh);

  color: #356354;
}
.pret {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: min(40px,6vh);
  line-height: min(49px,7vh);
}
.bubble {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #d9d9d980;
  height: min(525px,calc(95vh - 110px));
  width: min(525px,calc(95vh - 110px));
}
.text-bubble {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: min(304px,45vh);
  width: min(475px,calc(80vh - 110px));
}

@media (max-width: 600px) {
  .bubble {
    height: 300px;
    width: 300px;
  }
  .text-bubble {
    height: 152px;
    width: 265px;
  }
  .pret {
    font-size: 22px;
    line-height: 25px;
  }
  .blog {
    font-size: 35px;
    line-height: 42px;
  }
}
</style>

<script>
import { firestore, storage, auth, compression } from "../main";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
// eslint-disable-next-line
import { ref, uploadBytes, getDownloadURL, deleteObject, uploadBytesResumable } from "firebase/storage";
import OverlayPhoto from "../components/OverlayPhoto.vue";

export default {
  components: { OverlayPhoto },

  name: "Home",
  data: () => {
    return {
      recents: [],
      overlay: false,
      story_zoomed: 0,
      document: document,
      new_story: false,
      new_story_file: [],
      new_story_description: "",
      stories: [],
      admin: false,
      mainImageURL:"",
      main_text_color:"",
      secondary_text_color:"",
      updated_main_text_color:"",
      updated_secondary_text_color:"",
      changeTextColor:false,
      breaking_news:"",
      previous_breaking:"",
      editing_breaking:false,
      proportion:null,
    };
  },
  computed: {
    phone() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },
  methods: {
    async loadMap() {
      let visited_countries = await getDoc(
        doc(firestore, "stats", "visited_countries")
      ).then((doc) => doc.data());
      //eslint-disable-next-line
      var map = AmCharts.makeChart("mapdiv", {
        type: "map",
        theme: "dark",
        projection: "equal earth",
        panEventsEnabled: true,
        backgroundColor: "#F5F0D4",
        backgroundAlpha: 1,
        zoomControl: {
          zoomControlEnabled: true,
        },
        dataProvider: {
          map: "worldHigh",
          getAreasFromMap: true,
          areas: visited_countries.alpha.map((country) => {
            return { id: country, showAsSelected: true };
          }),
        },
        areasSettings: {
          autoZoom: true,
          color: "#8fb590",
          colorSolid: "#f7bf62",
          selectedColor: "#f7bf62",
          outlineColor: "#666666",
          rollOverColor: "#5d8276",
          rollOverOutlineColor: "#5d8276",
        },
      });
    },
    async addStory() {
      let url_image;
      let url_thumbnail;
      let random = Math.round(Math.random() * 100000)
      if (this.new_story_file.type.split("/")[0]=="video") {
        let data = await compression(this.new_story_file,2500,0.9);
        let ref_file = ref(
        storage,
        `stories/${random}-${
            this.new_story_file.name
          }`
        );
        let uploadTask = uploadBytesResumable(ref_file, data)
        let monitor = setInterval(()=>{
          this.proportion = uploadTask.snapshot.bytesTransferred/uploadTask.snapshot.totalBytes;
          if(uploadTask.snapshot.state == "success") {
            clearInterval(monitor);
            this.proportion = null;
            finish_upload();
          }
        },500);
        let finish_upload = async () => {
          url_image = await getDownloadURL(ref_file);
          url_thumbnail = url_image; 
          let story = {
            image_url: url_image,
            thumbnail_url: url_thumbnail,
            image_type: this.new_story_file.type.split("/")[0],
            description: this.new_story_description,
            date: new Date(),
            active: true,
          };
          await addDoc(collection(firestore, "stories"), story);
          this.stories.unshift(story);
          this.new_story_description = "";
          this.new_story_file = [];
          this.new_story = false;
        }
        return;        
      }
      else {
        let data = await compression(this.new_story_file,2500,0.9);
        let thumbnail = await compression(this.new_story_file,500,0.9);
        let ref_file = ref(
          storage,
          `stories/${random}-${
            this.new_story_file.name
          }`
        );
        let ref_thumbnail = ref(
          storage,
          `stories/thumbnail-${random}-${
            this.new_story_file.name
          }`
        );
        await Promise.all([uploadBytes(ref_file, data),uploadBytes(ref_thumbnail, thumbnail)]);
        url_image = await getDownloadURL(ref_file);
        url_thumbnail = await getDownloadURL(ref_thumbnail);
      }
      let story = {
        image_url: url_image,
        thumbnail_url: url_thumbnail,
        image_type: this.new_story_file.type.split("/")[0],
        description: this.new_story_description,
        date: new Date(),
        active: true,
      };
      await addDoc(collection(firestore, "stories"), story);
      this.stories.unshift(story);
      this.new_story_description = "";
      this.new_story_file = [];
      this.new_story = false;
    },
    openMainInput() {
      let input = this.document.getElementById("changeMainImage");
      input.click();
    },
    async changeMainImage(e) {
      if (e.target.files.length==0)
        return
      // eslint-disable-next-line
      let data = await compression(e.target.files[0],2500,0.8);      
      let ref_file = ref(
        storage,
        `imageProfil/${e.target.files[0].name}`
      );
      await uploadBytes(ref_file, data);
      let link_file = await getDownloadURL(ref_file);
      await updateDoc(doc(firestore, "stats","pagePrincipale"), {url:link_file});
      await deleteObject(ref(storage, this.mainImageURL));
      this.mainImageURL = link_file;
    },
    async updateTextColor() {
      await updateDoc(doc(firestore, "stats","pagePrincipale"), {main_text_color:this.updated_main_text_color, secondary_text_color:this.updated_secondary_text_color});
      this.main_text_color = this.updated_main_text_color;
      this.secondary_text_color = this.updated_secondary_text_color;
      this.changeTextColor = false;
      this.$forceUpdate();
    },
    async saveBreaking() {
      await updateDoc(doc(firestore,"stats","pagePrincipale"),{breaking_news:this.breaking_news})
      this.editing_breaking = false;
      this.previous_breaking = this.breaking_news;
    }
  },

  mounted() {
    let scripts = [
      "https://www.amcharts.com/lib/3/ammap.js",
      "https://www.amcharts.com/lib/3/maps/js/worldHigh.js",
      "https://www.amcharts.com/lib/3/themes/dark.js",
    ];
    let loaded = 0;
    scripts.forEach((script) => {
      let s = document.createElement("script");
      s.onload = () => {
        loaded += 1;
        if (loaded == 3) {
          setTimeout(this.loadMap,100);
        }
      };
      s.setAttribute("src", script);
      document.head.appendChild(s);
    });
  },
  async created() {
    let idToken = await auth.currentUser.getIdTokenResult();
    this.admin = !!idToken.claims.admin && idToken.claims.admin;
    let stories = await getDocs(
      query(
        query(collection(firestore, "stories"), where("active", "==", true)),
        orderBy("date", "desc")
      )
    );
    stories.forEach((snap) => {
      this.stories.push(snap.data());
    });
    this.recents = (
      await getDoc(doc(firestore, "stats", "recent_trips"))
    ).data().recent;
    this.recents.forEach(recent=>{getDoc(doc(firestore, "continents",recent.continent,"pays",recent.country,"lieux",recent.place_id)).then((snap)=>{recent.thumbnail_url = snap.data().thumbnail_url })})
    let data_page = (await getDoc(doc(firestore,"stats","pagePrincipale"))).data();
    this.mainImageURL = data_page.url
    this.main_text_color = data_page.main_text_color;
    this.updated_main_text_color = this.main_text_color;
    this.secondary_text_color = data_page.secondary_text_color;
    this.updated_secondary_text_color = this.main_text_color
    this.breaking_news = data_page.breaking_news
    this.previous_breaking = this.breaking_news
  },
};
</script>
