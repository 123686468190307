<template>
  <v-container>
    <overlay-photo
      v-if="!!day_zoomed && day_zoomed.images"
      :stories="day_zoomed.images.map(image=>{return {image_url:image,image_type:'image'}}) "
      :overlay="!!day_zoomed && day_zoomed.images"
      :removeOverlay="()=>day_zoomed=null"
      :index_zoomed="image_zoomed"
    />
    <v-overlay v-if="proportion">
      <v-card>
        <v-card-title>Envoie des photos en cours</v-card-title>
        <v-card-text>
          <v-progress-circular
            :rotate="-90"
            :size="100"
            :width="15"
            :value="proportion*100"
            color="teal"
          >
            {{ Math.floor(proportion*100) }}
          </v-progress-circular>

        </v-card-text>
      </v-card>
    </v-overlay>
    <v-overlay v-if="day_to_delete">
      <v-card>
        <v-card-title>
          Voulez-vous bien supprimer le jour {{day_to_delete.title && day_to_delete.title.length > 0? day_to_delete.title + " - " + day_to_delete.dateFrancaise : day_to_delete.dateFrancaise}}
        </v-card-title>
        <v-card-actions>
          <v-btn color="red" outlined @click="deleteDay">Oui</v-btn>
          <v-btn outlined @click="()=>day_to_delete=null">Non</v-btn>
        </v-card-actions>
      </v-card>
    </v-overlay>
    <v-card class="mb-6">
      <v-row v-if="!phone" class="ma-0" style="overflow:hidden">
        <v-col :class="{'col-5':place.mosaique && place.mosaique.length > 0, 'col-12': !place.mosaique || place.mosaique.length == 0, 'pa-0':true}"
          ><v-img
            width="100%"
            :aspect-ratio="place.mosaique && place.mosaique.length > 0 ? 1.34 : 2"
            height="100%"
            v-if="place.image_url"
            :src="place.image_url"
          ></v-img
        ></v-col>
        <v-col :class="{'col-7':place.mosaique && place.mosaique.length > 0, 'col-0': !place.mosaique || place.mosaique.length == 0 , 'pa-0':true}"
          ><v-row class="ma-0" wrap style="position: relative; height: 100%">
            <v-file-input
              accept="image/*"
              multiple
              v-model="new_images_mosaique"
              id="input_mosaique"
              style="display: none"
              @change="editMosaique"
            ></v-file-input>
            <v-btn
              v-if="admin"
              icon
              style="
                position: absolute;
                bottom: 0%;
                right: 0%;
                z-index: 2;
                transform: translate(-50%, -50%);
              "
              @click="
                (e) => {
                  e.preventDefault();
                  let input = document.getElementById('input_mosaique');
                  input.click();
                }
              "
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-col
              class="col-4 pa-0"
              v-for="image in place.mosaique"
              :key="image"
              ><v-img :src="image" width="100%" aspect-ratio="1.25"></v-img
            ></v-col> </v-row
        ></v-col>
      </v-row>
      <div v-else>
        <v-row class="ma-0" style="overflow:hidden">
        <v-img
            width="100%"
            aspect-ratio="1.5"
            height="100%"
            v-if="place.image_url"
            :src="place.image_url"
          ></v-img
        >
        </v-row>
        <v-card-title v-if="phone" class="text-h3 text-center mt-4 mb-4" style="display: grid;word-break:normal;">{{
        place.name
      }}</v-card-title>
        <v-row class="ma-0" wrap style="position: relative; height: 100%">
            <v-file-input
              accept="image/*"
              multiple
              v-model="new_images_mosaique"
              id="input_mosaique"
              style="display: none"
              @change="editMosaique"
            ></v-file-input>
            <v-btn
              icon
              v-if="admin"
              style="
                position: absolute;
                bottom: 0%;
                right: 0%;
                z-index: 2;
                transform: translate(-50%, -50%);
              "
              @click="
                (e) => {
                  e.preventDefault();
                  let input = document.getElementById('input_mosaique');
                  input.click();
                }
              "
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-col
              class="col-4 pa-0"
              v-for="image in place.mosaique"
              :key="image"
              ><v-img :src="image" width="100%" aspect-ratio="1.25"></v-img
            ></v-col> </v-row
        >
      </div>
      <v-card-title v-if="!phone" class="text-h3 text-center mt-6" style="display: grid">{{
        place.name
      }}</v-card-title>
      <v-card-subtitle v-if="place.description">{{
        place.description
      }}</v-card-subtitle>
      <v-card-text class="mt-7 px-8 pb-7" v-if="place.days && place.days.length > 0">
        <v-row wrap v-if="!phone">
          <v-col class="col-12 col-sm-4"
            ><v-row class="text--darken-3  grey--text">Premier jour</v-row>
            <v-row class="">{{
              place.days[place.days.length - 1].dateFrancaise
            }}</v-row></v-col
          >
          <v-col class="col-12 col-sm-4"
            ><v-row class="text--darken-3 grey--text">Dernier jour</v-row>
            <v-row>{{ place.days[0].dateFrancaise }}</v-row></v-col
          >
          <v-col class="col-12 col-sm-4"
            ><v-row class="text--darken-3 grey--text">Nombre de jours</v-row>
            <v-row>{{ place.days.length }}</v-row></v-col
          >
        </v-row>
        <div v-else>
          <v-row><span class="text--darken-3  grey--text">Premier jour : </span>	&nbsp;{{
              place.days[place.days.length - 1].dateFrancaise
            }}</v-row>
          <v-row><span class="text--darken-3  grey--text">Dernier jour : </span>	&nbsp;{{
              place.days[0].dateFrancaise
            }}</v-row>
          <v-row><span class="text--darken-3  grey--text">Nombre de jours : </span>	&nbsp;{{
              place.days.length
            }}</v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      class="mb-4"
      v-for="day in place.days"
      :key="day.date.toISOString()"
    >
      <v-card-title
        ><div>
          {{
            day.title && day.title.length > 0
              ? day.title + " - " + day.dateFrancaise
              : day.dateFrancaise
          }}
        </div>
        <v-spacer></v-spacer>
        <div>
          <v-btn v-if="admin" icon @click="() => day_to_delete = day">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-btn v-if="admin" icon @click="() => startEditDay(day)">
            <v-icon>edit</v-icon>
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="mb-4 text-body-1 text-justify" style="white-space:pre-line;">
          {{ day.description }}
        </div>

        <v-row class="mx-1 my-3">
          <v-col
            class="col-6 col-sm-4 col-md-3 d-flex child-flex py-md-0 px-md-2"
            style="position: relative"
            v-for="(img, i) in day.thumbnails"
            :key="img"
          >
            <v-btn
              v-if="admin"
              @click="() => deleteImage(day, i)"
              icon
              style="
                position: absolute;
                top: 0px;
                right: 0px;
                transform: translate(-50%, 50%);
                z-index: 2;
              "
            >
              <v-icon>delete</v-icon>
            </v-btn>
            <v-img
              @click="
                () => {
                  day_zoomed = day;
                  image_zoomed = i;
                }
              "
              style="cursor:pointer"
              :src="img"
              aspect-ratio="1"
              contain
            ></v-img>
          </v-col>
        </v-row>
        <div class="text-h6">Commentaires</div>
        <v-list
          v-if="day.comments && day.comments.length > 0"
          dense
          class="mt-3"
          style="max-height: 60vh; overflow-y: scroll"
        >
          <div v-for="comment in day.comments" :key="comment.text">
            <v-list-item>
              <div>
                <div class="flex" style="display: flex">
                  <span class="text-decoration-underline pt-2">{{
                    comment.author
                  }} {{comment.date ? " - "+ dateHeure(comment.date) : ""}}</span>
                  <v-spacer></v-spacer
                  ><v-btn
                    class="mb-1"
                    @click="
                      () => {
                        day.answer = comment;
                      }
                    "
                    icon
                    ><v-icon>reply</v-icon></v-btn
                  >
                  <v-btn
                    icon
                    @click="() => deleteComment(day, comment)"
                    v-if="comment.author_uid == uid"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </div>
                <v-sheet
                  v-if="comment.response_text"
                  rounded
                  color="grey lighten-2"
                  class="py-1 my-2 pl-3 ml-3 pr-2"
                >
                  <div class="text-decoration-underline">
                    {{ comment.response_author }}
                  </div>
                  <div style="white-space:pre-line;">{{ comment.response_text }}</div>
                </v-sheet>
                <div style="white-space:pre-line;">{{ comment.text }}</div>
              </div>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
        <v-sheet
          v-if="day.answer"
          color="grey lighten-2"
          rounded
          class="py-1 my-2 pl-3 ml-3 pr-2"
        >
          <div class="flex" style="display: flex">
            <span class="text-decoration-underline pt-2">{{
              day.answer.author
            }}</span>
            <v-spacer></v-spacer
            ><v-btn
              class="mb-1"
              @click="
                () => {
                  day.answer = null;
                }
              "
              icon
              ><v-icon>cancel</v-icon></v-btn
            >
          </div>
          <div style="white-space:pre-line;">{{ day.answer.text }}</div>
        </v-sheet>
        <v-row class="mx-3 mt-2">
          <v-textarea
            auto-grow
            rows="1"
            label="Nouveau commentaire"
            v-model="day.new_comment"
          ></v-textarea>
          <v-btn
            :disabled="day.new_comment.length == 0"
            color="blue"
            icon
            class="mt-3"
            @click="() => sendMessage(day)"
          >
            <v-icon>send</v-icon>
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="admin">
      <v-card-title>Nouvelle journée</v-card-title>
      <v-card-text>
        <v-row
          ><v-col class="col-8"
            ><v-text-field
              label="Titre de la journée"
              v-model="new_day_title"
              :disabled="!new_day_title_present"
            ></v-text-field></v-col
          ><v-col class="col-4"
            ><v-switch
              v-model="new_day_title_present"
              label="Donner un titre"
            ></v-switch></v-col
        ></v-row>
        <v-textarea
          outlined
          label="Description de la journée"
          v-model="new_day_description"
        ></v-textarea>
        <v-file-input
          multiple
          counter
          accept="image/*"
          prepend-icon="mdi-camera"
          label="Photos journée"
          v-model="new_day_images"
        ></v-file-input>
        <v-date-picker v-model="new_day_date"></v-date-picker>
      </v-card-text>
      <v-card-actions
        ><v-btn @click="addNewDay">Ajouter journée</v-btn></v-card-actions
      >
    </v-card>
    <PDFCreator v-if="admin" :place="place"/>
  </v-container>
</template>

<script>
import { firestore, auth, storage, compression } from "../main";
import {
  getDoc,
  doc,
  collection,
  getDocs,
  updateDoc,
  arrayUnion,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  uploadBytesResumable,
} from "firebase/storage";
import { Place, Day, Comment, dateHeure } from "../classes";
import OverlayPhoto from "../components/OverlayPhoto.vue";
import PDFCreator from "../components/PDFCreator.vue";

export default {
  components: { OverlayPhoto, PDFCreator },
  data() {
    return {
      place: new Place(),
      day_zoomed: null,
      image_zoomed: null,
      new_day_title_present: false,
      new_day_title: "",
      new_day_description: "",
      new_day_images: [],
      new_day_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      editing_day: null,
      admin: false,
      new_images_mosaique: [],
      day_to_delete:null,
      proportion:null,
    };
  },
  computed: {
    continent() {
      return this.$route.params.continent;
    },
    country() {
      return this.$route.params.country;
    },
    place_id() {
      return this.$route.params.place;
    },
    document() {
      return document;
    },
    uid() {
      return auth.currentUser.uid;
    },
    phone() {
      return this.$vuetify.breakpoint.name=="xs"
    }
  },
  methods: {
    dateHeure(date) {return dateHeure(date)},
    sendMessage(day) {
      let day_ref = doc(
        firestore,
        "continents",
        this.continent,
        "pays",
        this.country,
        "lieux",
        this.place_id,
        "days",
        day.firestore_id
      );
      let new_comment = {
        text: day.new_comment,
        author: auth.currentUser.displayName,
        author_uid: auth.currentUser.uid,
        date:new Date()
      };
      if (day.answer) {
        new_comment.response_text = day.answer.text;
        new_comment.response_author = day.answer.author;
      }
      updateDoc(day_ref, { comments: arrayUnion(new_comment) });
      day.answer = null;
      day.new_comment = "";
      day.comments.push(
        new Comment(
          new_comment.text,
          new_comment.author,
          new_comment.author_uid,
          new_comment.date,
          new_comment.response_text,
          new_comment.response_author
        )
      );
      this.$forceUpdate();
    },
    dateFromString(str) {
      return new Date(
        Number(str.split("-")[0]),
        Number(str.split("-")[1]) - 1,
        Number(str.split("-")[2]),
        12
      );
    },


    async addNewDay() {
      let new_day_ref;
      if (this.editing_day)
        new_day_ref = doc(
          firestore,
          "continents",
          this.continent,
          "pays",
          this.country,
          "lieux",
          this.place_id,
          "days",
          this.editing_day.firestore_id
        );
      else
        new_day_ref = doc(
          collection(
            firestore,
            "continents",
            this.continent,
            "pays",
            this.country,
            "lieux",
            this.place_id,
            "days"
          )
        );
      let random = []
      for (let i = 0;i<this.new_day_images.length;i++)
        random.push(Math.round(Math.random()*10000))
      
      let ref_thumbnails = this.new_day_images.map((image,i) =>
        ref(
          storage,
          `countries/${this.country}/${this.place_id}/${new_day_ref.id}/thumbnail-${random[i]}-${image.name}`
        )
      );
      let thumbnails = await Promise.all(
        this.new_day_images.map((image) => compression(image,500,0.8))
      );
      await Promise.all(
        thumbnails.map(async (data, i) => {
          return uploadBytes(ref_thumbnails[i], data);
        })
      );
      let url_thumbnails = await Promise.all(
        ref_thumbnails.map((ref) => getDownloadURL(ref))
      );

      let ref_images = this.new_day_images.map((image,i) =>
        ref(
          storage,
          `countries/${this.country}/${this.place_id}/${new_day_ref.id}/${random[i]}-${image.name}`
        )
      );
      let data_images = await Promise.all(
        this.new_day_images.map((image) => compression(image,2000,0.9))
      );
      let uploadTasks = data_images.map((data, i) => {return uploadBytesResumable(ref_images[i], data)})
      let monitor = setInterval(()=>{
        let total = 0;
        let uploaded = 0;
        let done = true;
        for (let task of uploadTasks) {
          uploaded += task.snapshot.bytesTransferred;
          total += task.snapshot.totalBytes;
          done = done && task.snapshot.state == "success";
        }
        this.proportion = uploaded/total;
        if(done) {
          clearInterval(monitor);
          this.proportion = null;
          finish_upload();
        }
      },500);
      let finish_upload = async ()=> {
        let url_images = await Promise.all(
          ref_images.map((ref) => getDownloadURL(ref))
        );
        if (this.editing_day) {
          this.editing_day.title = this.new_day_title_present
            ? this.new_day_title
            : null;
          this.editing_day.description = this.new_day_description;
          this.editing_day.date = this.dateFromString(this.new_day_date);
          this.editing_day.images = this.editing_day.images.concat(url_images);
          this.editing_day.thumbnails = this.editing_day.thumbnails.concat(url_thumbnails);
          await updateDoc(new_day_ref, this.editing_day.toObject());
        } else {
          let new_day = new Day(
            new_day_ref.id,
            this.dateFromString(this.new_day_date),
            this.new_day_description,
            url_images,
            url_thumbnails,
            this.new_day_title_present ? this.new_day_title : null
          );
          await setDoc(new_day_ref, new_day.toObject());
          this.place.days.push(new_day);
        }
        this.place.days = this.place.days.sort((a, b) => b.date - a.date);
        this.new_day_title = "";
        this.new_day_description = "";
        this.new_day_images = [];
        this.editing_day = null;
        window.scroll(0,0);
      }
      
      
    }
    
    
    
    ,
    startEditDay(day) {
      this.editing_day = day;
      this.new_day_title_present = !!day.title;
      this.new_day_title = day.title ? day.title : "";
      this.new_day_description = day.description;
      this.new_day_date = new Date(
        day.date - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.new_day_images = [];
      window.scrollTo(0, document.body.scrollHeight);
    },
    async deleteImage(day, ind) {
      await deleteObject(ref(storage, day.images[ind]));
      await deleteObject(ref(storage, day.thumbnails[ind]));
      day.images.splice(ind, 1);
      day.thumbnails.splice(ind,1);
      await updateDoc(
        doc(
          firestore,
          "continents",
          this.continent,
          "pays",
          this.country,
          "lieux",
          this.place_id,
          "days",
          day.firestore_id
        ),
        { images: day.images,thumbnails:day.thumbnails }
      );
    },
    async deleteComment(day, comment) {
      day.comments.splice(day.comments.indexOf(comment), 1);
      await updateDoc(
        doc(
          firestore,
          "continents",
          this.continent,
          "pays",
          this.country,
          "lieux",
          this.place_id,
          "days",
          day.firestore_id
        ),
        { comments: day.comments.map(comment=>comment.toObject()) }
      );
    },
    async deleteDay() {
      if (this.day_to_delete.images && this.day_to_delete.thumbnails)
        await Promise.all([this.day_to_delete.images.map(image=>deleteObject(ref(storage, image)))]+ [this.day_to_delete.thumbnails.map(thumbnail=>deleteObject(ref(storage, thumbnail)))]);
      let day_ref = doc(
        firestore,
        "continents",
        this.continent,
        "pays",
        this.country,
        "lieux",
        this.place_id,
        "days",
        this.day_to_delete.firestore_id
      );
      await deleteDoc(day_ref);
      this.place.days.splice(this.place.days.indexOf(this.day_to_delete),1);
      this.day_to_delete = null;
    },
    async editMosaique() {
      try {
        if (this.place.mosaique && this.place.mosaique.length > 0) {
          for (let url of this.place.mosaique)
            deleteObject(ref(storage,url));
        }
      } catch(e) {console.log(e)}
      
      this.new_images_mosaique = this.new_images_mosaique.slice(0, 6);
      let ref_images = this.new_images_mosaique.map((image) =>
        ref(
          storage,
          `countries/${this.country}/${this.place_id}/${Math.floor(
            Math.random() * 1000
          )}-${image.name}`
        )
      );
      let data_images = await Promise.all(
        this.new_images_mosaique.map((image) => compression(image,600,0.9))
      );
      await Promise.all(
        data_images.map(async (data, i) => {
          return uploadBytes(ref_images[i], data);
        })
      );
      let link_images = await Promise.all(
        ref_images.map((ref) => getDownloadURL(ref))
      );
      let place_ref = doc(
        firestore,
        "continents",
        this.continent,
        "pays",
        this.country,
        "lieux",
        this.place_id
      );
      updateDoc(place_ref, { mosaique: link_images });
      this.place.mosaique = link_images;
      this.$forceUpdate();
    },
  },
  async created() {
    let idToken = await auth.currentUser.getIdTokenResult();
    this.admin = !!idToken.claims.admin && idToken.claims.admin;
    let document = await getDoc(
      doc(
        firestore,
        "continents",
        this.continent,
        "pays",
        this.country,
        "lieux",
        this.place_id
      )
    );
    let data = document.data();
    this.place = new Place(
      data.name,
      document.id,
      [],
      data.image_url,
      data.thumbnail_url,
      null,
      data.mosaique
    );
    let days = await getDocs(
      collection(
        firestore,
        "continents",
        this.continent,
        "pays",
        this.country,
        "lieux",
        this.place_id,
        "days"
      )
    );

    days.forEach((day) => {
      let data = day.data();
      this.place.days.push(
        new Day(
          day.id,
          new Date(data.date.seconds * 1000),
          data.description,
          data.images,
          data.thumbnails,
          data.title,
          data.comments.map((comment) => {
            return new Comment(
              comment.text,
              comment.author,
              comment.author_uid,
              comment.date,
              comment.response_text,
              comment.response_author
            );
          })
        )
      );
    });
    this.place.days = this.place.days.sort((a, b) => b.date - a.date);
  },

  async beforeRouteUpdate(to, _, next) {
        if( to.name!="Destination")
            return;
        let document = await getDoc(
          doc(
            firestore,
            "continents",
            to.params.continent,
            "pays",
            to.params.country,
            "lieux",
            to.params.place
          )
        );
        let data = document.data();
        this.place = new Place(
          data.name,
          document.id,
          [],
          data.image_url,
          data.thumbnail_url,
          null,
          data.mosaique
        );
        let days = await getDocs(
          collection(
            firestore,
            "continents",
            to.params.continent,
            "pays",
            to.params.country,
            "lieux",
            to.params.place,
            "days"
          )
        );

        days.forEach((day) => {
          let data = day.data();
          this.place.days.push(
            new Day(
              day.id,
              new Date(data.date.seconds * 1000),
              data.description,
              data.images,
              data.thumbnails,
              data.title,
              data.comments.map((comment) => {
                return new Comment(
                  comment.text,
                  comment.author,
                  comment.author_uid,
                  comment.date,
                  comment.response_text,
                  comment.response_author
                );
              })
            )
          );
        });
        this.place.days = this.place.days.sort((a, b) => b.date - a.date);
        next();
        return true;
        
    }

};
</script>

<style>
</style>